<template>
  <v-container fluid>
    <PageHeaderSlot showBackButton>
      <template slot="top-right-slot" v-if="$validate.DataValid(id)">
        <v-btn text depressed class="primary ml-2" @click="copyStudentUrl()">{{ $t('copyStudentUrl') }}</v-btn>
        <v-btn v-if="canViewOrder" text depressed class="primary ml-2" :to="{ name: 'OrderList', params: { sid: id } }">{{ $t('paymentRecord') }}</v-btn>
        <v-btn v-if="canViewAttendance" text depressed class="primary ml-2" :to="{ name: 'StudentAttendance', params: { id: id } }">{{ $t('attendanceRecord') }}</v-btn>
        <v-btn v-if="canRemission && formData.status === 'inactive'" text depressed class="primary ml-2" @click="handleStudentRemission()">{{ $t('remission') }}</v-btn>
        <v-btn v-else-if="canQuit && formData.status !== 'inactive'" text depressed class="primary ml-2 error" @click="handleStudentQuit()" :loading="$store.getters.isLoading">{{ $t('quit') }}</v-btn>
      </template>
    </PageHeaderSlot>

    <v-form ref="dataForm" @submit.prevent="">
      <v-card>
        <v-card-text class="px-2">
          <v-row class="ma-0">
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                label="studentCode"
                placeholder="studentCode"
                :fieldValue.sync="formData.student_code"
                dense
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormSelect
                label="campus"
                placeholder="selectCampus"
                :fieldValue.sync="formData.school_id"
                dense
                :options="tutorSchoolOptions"
                required
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                label="remark"
                placeholder="remark"
                :fieldValue.sync="formData.remark"
                dense
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                label="name"
                placeholder="name"
                :fieldValue.sync="formData.student_name"
                dense
                required
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                label="engName"
                placeholder="engName"
                :fieldValue.sync="formData.student_eng_name"
                dense
                isEngNameOnly
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormSelect
                label="grade"
                placeholder="selectGrade"
                :fieldValue.sync="formData.grade"
                dense
                :options="$gradeOptions"
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                label="studentSchool"
                placeholder="studentSchool"
                :fieldValue.sync="formData.student_school_name"
                dense
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                type="tel"
                label="studentPhone"
                placeholder="studentPhone"
                :fieldValue.sync="formData.phone"
                dense
                isIntegerOnly
                required
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              >
                <template slot="append-outer">
                  <v-btn
                    class="whatsapp my-0"
                    text
                    :href="$validate.DataValid(formData.phone) ? `https://wa.me/${formData.phone}` : ''"
                    target="_blank"
                    min-width="57px"
                    max-width="57px"
                  >
                    <v-icon size="24" color="white">{{ icon.mdiWhatsapp }}</v-icon>
                  </v-btn>
                </template>
              </FormInput>
            </v-col>
            <v-col cols="12" md="8" lg="6">
              <FormInput
                label="homeAddress"
                placeholder="homeAddress"
                :fieldValue.sync="formData.address"
                dense
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormDatePicker
                label="birthday"
                placeholder="birthday"
                :dateValue.sync="formData.birth_date"
                dense
                :maxDate="birthDateMax"
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                readonly
                label="age"
                placeholder="age"
                :fieldValue.sync="age"
                dense
                isIntegerOnly
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormSelect
                label="gender"
                placeholder="gender"
                :fieldValue.sync="formData.gender"
                dense
                :options="$genderOptions"
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormSelect
                label="memberLevel"
                placeholder="selectMemberLevel"
                :fieldValue.sync="formData.member_level"
                dense
                :options="$memberOptions"
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>

            <v-col cols="12" sm="6" :md="$validate.DataValid(id) && canEdit ? '5' : '4'" :lg="$validate.DataValid(id) && canEdit ? '4' : '3'">
              <FormInput
                label="accountName"
                placeholder="accountName"
                :fieldValue.sync="formData.username"
                dense
                required
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              >
                <template slot="append-outer" v-if="$validate.DataValid(id) && canEdit">
                  <v-btn class="primary" depressed text @click="handlePasswordDialogOpen()">{{ $t('resetPassword') }}</v-btn>
                </template>
              </FormInput>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                v-if="!$validate.DataValid(id)"
                label="password"
                type="password"
                :fieldValue.sync="formData.password"
                dense
                :required="!$validate.DataValid(id)"
                :customRules="[
                  v => {
                    return this.$validate.isValidPassword(v, false)
                  },
                ]"
                showPwView
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="pa-0">
          <v-row class="ma-0 px-2">
            <v-col cols="12">
              <h2 class="colorBlack--text">{{ $t('contactPerson') }}</h2>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="ma-0 px-2 pt-3">
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                :label="$t('contactPersonName', { number: 1 })"
                :placeholder="$t('contactPersonName', { number: 1 })"
                :fieldValue.sync="formData.contact_person_1_name"
                dense
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                type="tel"
                :label="$t('contactPersonPhone', { number: 1 })"
                :placeholder="$t('contactPersonPhone', { number: 1 })"
                :fieldValue.sync="formData.contact_person_1_phone"
                dense
                isIntegerOnly
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              >
                <template slot="append-outer">
                  <v-btn
                    class="whatsapp my-0"
                    text
                    :href="
                      $validate.DataValid(formData.contact_person_1_phone)
                        ? `https://wa.me/${formData.contact_person_1_phone}`
                        : ''
                    "
                    target="_blank"
                    min-width="57px"
                    max-width="57px"
                  >
                    <v-icon size="24" color="white">{{ icon.mdiWhatsapp }}</v-icon>
                  </v-btn>
                </template>
              </FormInput>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                :label="$t('contactPersonName', { number: 2 })"
                :placeholder="$t('contactPersonName', { number: 2 })"
                :fieldValue.sync="formData.contact_person_2_name"
                dense
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormInput
                type="tel"
                 :label="$t('contactPersonPhone', { number: 2 })"
                :placeholder="$t('contactPersonPhone', { number: 2 })"
                :fieldValue.sync="formData.contact_person_2_phone"
                dense
                isIntegerOnly
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              >
                <template slot="append-outer">
                  <v-btn
                    class="whatsapp my-0"
                    text
                    :href="
                      $validate.DataValid(formData.contact_person_2_phone)
                        ? `https://wa.me/${formData.contact_person_2_phone}`
                        : ''
                    "
                    target="_blank"
                    min-width="57px"
                    max-width="57px"
                  >
                    <v-icon size="24" color="white">{{ icon.mdiWhatsapp }}</v-icon>
                  </v-btn>
                </template>
              </FormInput>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <v-btn class="primary mt-4" text depressed @click="save()" :loading="$store.getters.isLoading" v-if="canEdit">{{ $t('save') }}</v-btn>

    <Datatable
      v-if="$validate.DataValid(id)"
      class="mt-6"
      tableName="performance"
      :isLoading="scoreTableLoading"
      :tableData="scoreTableData"
      :tableHeaders="performanceTableHeaders"
      disableFooter
      disablePagination
      @edit-clicked="handlePerformanceDialogOpen('edit', $event)"
      @delete-clicked="handleDeletePerformance($event)"
    >
      <template slot="append-toolbar-left">
        <v-btn text depressed class="primary ml-4 my-2" @click.prevent="handlePerformanceDialogOpen('new')">
          <v-icon size="20" class="mr-2">{{ icon.mdiPlusCircleOutline }}</v-icon>
          {{ $t('createPerformance') }}
        </v-btn>
      </template>

        <template v-slot:[`item.semester`]="{ item }">
            <span v-if="item.semester">{{ $t('semesterOption.' + item.semester) }}</span>
        </template>
    </Datatable>

    <!-- 重設密碼 Dialog -->
    <FormDialog
      title="resetPassword"
      btnAlign="center"
      ref="passwordFormDialog"
      formRef="passwordForm"
      @submit-clicked="handlePasswordSubmit()"
      v-if="$validate.DataValid(id)"
    >
      <v-row class="ma-0">
        <v-col cols="12">
          <FormInput
            label="newPassword"
            type="password"
            :fieldValue.sync="newPassword"
            dense
            required
            :customRules="[
              v => {
                return this.$validate.isValidPassword(v, false)
              },
            ]"
          />
        </v-col>
        <v-col cols="12">
          <FormInput
            label="confirmNewPassword"
            type="password"
            :fieldValue.sync="confirmPassword"
            dense
            required
            :customRules="[
              v => {
                return !$validate.DataValid(v) || ($validate.DataValid(v) && v === newPassword) || $t('message.passwordNotMatch')
              },
            ]"
          />
        </v-col>
      </v-row>
    </FormDialog>

    <!-- 新增成績 Dialog -->
    <FormDialog
      :title="performanceSubmitType === 'edit' ? 'editPerformance' : 'createPerformance'"
      :maxWidth="910"
      ref="performanceDialog"
      formRef="performanceForm"
      @submit-clicked="handlePerformanceSubmit()"
    >
      <v-row class="ma-0">
        <v-col cols="12" md="6">
          <FormSelect
            label="grade"
            placeholder="selectGrade"
            :fieldValue.sync="performanceFormData.grade"
            dense
            :options="$gradeOptions"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <FormSelect
            label="semester"
            placeholder="selectSemester"
            :fieldValue.sync="performanceFormData.semester"
            dense
            :options="semesterOptions"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <FormSelect
            label="subject"
            placeholder="selectSubject"
            :fieldValue.sync="performanceFormData.subject"
            dense
            :options="$subjectOptions"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <FormInput
            label="examName"
            placeholder="examName"
            :fieldValue.sync="performanceFormData.exam_name"
            dense
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <FormInput label="score" placeholder="score" :fieldValue.sync="performanceFormData.score" dense required />
        </v-col>
      </v-row>
    </FormDialog>

    <ConfirmDialog ref="confirmDeletePerformanceDialog" enableShowRemindCheckbox/>
    <ConfirmDialog ref="confirmStudentQuitDialog" title="message.confirmQuit" :isDeleteDialog="false">{{ $t('message.confirmQuitMsg') }}</ConfirmDialog>
    <ConfirmDialog ref="confirmStudentRemissionDialog" title="message.confirmRemission" :isDeleteDialog="false">{{ $t('message.confirmRemissionMsg') }}</ConfirmDialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiWhatsapp, mdiPlusCircleOutline } from '@mdi/js'
import Datatable from '@/components/Datatable.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import FormInput from '@/components/formField/FormInput.vue'
import FormSelect from '@/components/formField/FormSelect.vue'
import FormDatePicker from '@/components/formField/FormDatePicker.vue'
import FormDialog from '@/components/FormDialog.vue'
import { cannotAccessList } from '@/assets/cannotAccessList'

export default {
  name: 'StudentDetails',
  components: {
    Datatable,
    ConfirmDialog,
    FormInput,
    FormSelect,
    FormDatePicker,
    FormDialog,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    birthDateMax() {
      const todayYear = new Date().getFullYear()
      const maxYear = todayYear - this.minAge
      if (maxYear === todayYear) {
        return this.$formatter.formatDate(new Date());
      }
      return `${maxYear}-12-31`
    },
    age() {
      if (this.$validate.DataValid(this.formData.birth_date)) {
        const birthYear = parseInt(this.formData.birth_date.substring(0, 4))
        return (new Date().getFullYear() - birthYear).toString()
      }

      return ''
    },
    dispatchUpdateOnChange() {
      if (this.$validate.DataValid(this.id)) {
        return true
      } else {
        return false
      }
    },
  },
  data: (vm) => ({
    initPhone: '',
    initUsername: '',
    formData: {
      school_id: '',
      student_name: '',
      student_eng_name: '',
      grade: '',
      student_school_name: '',
      phone: '',
      address: '',
      birth_date: '',
      gender: '',
      member_level: 'normal',
      username: '',
      contact_person_1_name: '',
      contact_person_1_phone: '',
      contact_person_2_name: '',
      contact_person_2_phone: '',
      password: '',
      status: 'active',
      remark: '',
      student_code: ''
    },
    tutorSchoolOptions: [],
    newPassword: '',
    confirmPassword: '',

    scoreTableData: [],
    scoreTableLoading: false,
    performanceSubmitType: '', // 'new' | 'edit'
    performanceFormData: {
      grade: '',
      semester: '',
      subject: '',
      exam_name: '',
      score: '',
    },

    // --- Static data
    icon: {
      mdiWhatsapp,
      mdiPlusCircleOutline,
    },
    minAge: 0,
    maxAge: 20,
    performanceTableHeaders: [
      { value: 'create_date', text: vm.$t('createDate'), width: 130 },
      { value: 'grade', text: vm.$t('grade') },
      { value: 'semester', text: vm.$t('semester') },
      { value: 'subject', text: vm.$t('subject') },
      { value: 'exam_name', text: vm.$t('examName') },
      { value: 'score', text: vm.$t('score') },
      { value: 'minActionEditAndDelete', text: '' },
    ],
    semesterOptions: [
      { value: 'upper', text: vm.$t('semesterOption.upper') },
      { value: 'lower', text: vm.$t('semesterOption.lower') },
    ],

    canViewOrder: true,
    canViewAttendance: true,
    canRemission: true,
    canQuit: true,
    canEdit: true,
  }),
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
    async getStudentById() {
      this.scoreTableLoading = true

      try {
        const data = await this.$Fetcher.GetStudentById(this.id)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.formData[key] = data[key]
          }
        }
        this.initPhone = data.phone
        this.initUsername = data.username

        await this.getStudentScores()
      } catch {
        this.setDialogMessage({
          message: 'noData',
          returnLink: { name: 'StudentList' },
        })
        this.setShowDialog(true)
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },

    async getStudentScores() {
      this.scoreTableLoading = true
      this.scoreTableData = []
      try {
        const payload = {
          filter_item: [{ key: 'student_id', value: this.id }],
        }
        const { data } = await this.$Fetcher.GetStudentScores(payload)
        this.scoreTableData = data.map(el => {
          return {
            id: el.id,
            create_date: el.create_date,
            grade: el.grade,
            semester: el.semester,
            subject: el.subject,
            exam_name: el.exam_name,
            score: el.score,
          }
        })
      } catch (err) {
        this.scoreTableData = []
      } finally {
        this.scoreTableLoading = false
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.$store.dispatch('setLoading', true)

      const valid = this.$refs['dataForm'].validate()

      if (!valid) {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'required',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      const payload = {
        school_id: this.formData.school_id,
        student_name: this.formData.student_name,
        student_eng_name: this.formData.student_eng_name,
        grade: this.formData.grade,
        student_school_name: this.formData.student_school_name,
        phone: this.formData.phone,
        address: this.formData.address,
        birth_date: this.formData.birth_date,
        gender: this.formData.gender,
        member_level: this.formData.member_level,
        username: this.formData.username,
        password: this.formData.password,
        contact_person_1_name: this.formData.contact_person_1_name,
        contact_person_1_phone: this.formData.contact_person_1_phone,
        contact_person_2_name: this.formData.contact_person_2_name,
        contact_person_2_phone: this.formData.contact_person_2_phone,
        remark: this.formData.remark,
        student_code: this.formData.student_code
      }

      if (this.$validate.DataValid(this.id)) {
        try {
          await this.$Fetcher.UpdateStudent(this.id, payload)

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.saveSuccess',
            type: 'success',
            refresh: true,
            redirect: '',
          })
          this.$store.dispatch('setDataIsUpdated', false)
        } catch (err) {
          this.setDialogMessage({
            title: 'message.saveFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        payload.password = this.formData.password

        try {
          await this.$Fetcher.NewStudent(payload)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.createSuccess',
            type: 'success',
            refresh: false,
            redirect: 'StudentList',
          })
        } catch (err) {
          this.setDialogMessage({
            title: 'message.createFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    copyStudentUrl() {
      const vm = this;
      const url = `${window.location.origin}/student-login/${this.initUsername}`
      this.$copyText(url).then(function (e) {
        vm.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.copied',
          type: 'success',
          refresh: false,
          redirect: '',
        })
      }, function (e) {
        vm.$common.error(e);
      })
    },

    // ======== 分校 Options =======
    async getAllSchoolOptions() {
      try {
        const { data } = await this.$Fetcher.GetSchools()
        const filtered = data.filter(el => el.active === true)
        if (this.$validate.DataValid(filtered)) {
          this.tutorSchoolOptions = filtered.map(el => {
            return {
              value: el.id,
              text: el.name,
            }
          })
        }
      } catch {}
    },

    // ======== 學生退學/重新入學 =======
    async handleStudentQuit() {
      const confirm = await this.$refs.confirmStudentQuitDialog.show()
      if (confirm) {
        this.$store.dispatch('setLoading', true)
        try {
          await this.$Fetcher.StudentQuit(this.id)
          this.formData.status = 'inactive'
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.quitSuccess',
            type: 'success',
            refresh: false,
            redirect: '',
          })
        } catch (err) {
          this.setDialogMessage({
            title: 'message.quitFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    async handleStudentRemission() {
      const confirm = await this.$refs.confirmStudentRemissionDialog.show()
      if (confirm) {
        this.$store.dispatch('setLoading', true)
        try {
          await this.$Fetcher.StudentRemission(this.id)
          this.formData.status = 'active'
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.remissionSuccess',
            type: 'success',
            refresh: false,
            redirect: '',
          })
        } catch (err) {
          this.setDialogMessage({
            title: 'message.remissionFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    // ======== 重設密碼 =======
    async handlePasswordDialogOpen() {
      this.newPassword = ''
      this.confirmPassword = ''
      await this.$refs['passwordFormDialog'].resetForm()
      this.$refs['passwordFormDialog'].show()
    },

    async handlePasswordSubmit() {
      try {
        await this.$Fetcher.StudentChangePassword(this.id, this.newPassword)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.updateSuccess',
          type: 'success',
          refresh: false,
        })
        this.$refs['passwordFormDialog'].hide()
      } catch (err) {
        this.setDialogMessage({
          title: 'message.updateFail',
          message: err,
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
        this.$refs['passwordFormDialog'].setLoading(false)
      }
    },

    // ======== 新增成績 =======
    async handlePerformanceDialogOpen(type, id) {
      this.performanceSubmitType = type

      // -- reset form
      this.performanceFormData.grade = ''
      this.performanceFormData.semester = ''
      this.performanceFormData.subject = ''
      this.performanceFormData.exam_name = ''
      this.performanceFormData.score = ''
      await this.$refs['performanceDialog'].resetForm()

      if (type === 'edit' && this.$validate.DataValid(id)) {
        this.$refs['performanceDialog'].setEditId(id)

        const target = this.scoreTableData.find(el => el.id === id)
        if (target) {
          this.performanceFormData.grade = target.grade
          this.performanceFormData.semester = target.semester
          this.performanceFormData.subject = target.subject
          this.performanceFormData.exam_name = target.exam_name
          this.performanceFormData.score = target.score
        }
      }

      if (type === 'new' || (type === 'edit' && this.$validate.DataValid(id))) {
        this.$refs['performanceDialog'].show()
      }
    },

    async handlePerformanceSubmit() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      let payload = {
        student_id: this.id,
        exam_name: this.performanceFormData.exam_name,
        grade: this.performanceFormData.grade,
        semester: this.performanceFormData.semester,
        subject: this.performanceFormData.subject,
        score: this.performanceFormData.score,
      }

      if (this.performanceSubmitType === 'new') {
        try {
          await this.$Fetcher.NewStudentScore(payload)
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.createSuccess',
            type: 'success',
            refresh: false,
          })

          this.$refs['performanceDialog'].hide()
          this.getStudentScores()
        } catch (err) {
          this.setDialogMessage({
            title: 'message.createFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
          this.$refs['passwordFormDialog'].setLoading(false)
        }
      } else if (this.performanceSubmitType === 'edit') {
        const editId = this.$refs['performanceDialog'].getEditId()
        try {
          await this.$Fetcher.UpdateStudentScore(editId, payload)
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.updateSuccess',
            type: 'success',
            refresh: false,
            redirect: '',
          })

          this.$refs['performanceDialog'].hide()
          this.getStudentScores()
        } catch (err) {
          this.setDialogMessage({
            title: 'message.updateFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
          this.$refs['performanceDialog'].setLoading(false)
        }
      }
    },

    async handleDeletePerformance(id) {
      const confirm = await this.$refs.confirmDeletePerformanceDialog.show()
      if (confirm) {
        const targetPos = this.scoreTableData.findIndex(el => el.id === id)
        if (targetPos > -1) {
          try {
            await this.$Fetcher.DeleteStudentScore(id)
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: 'message.deleteSuccess',
              type: 'success',
              refresh: false,
              redirect: '',
            })
            this.scoreTableData.splice(targetPos, 1)
          } catch (err) {
            this.setDialogMessage({
              title: 'message.deleteFail',
              message: err,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          }
        }
      }
    },
  },
  async mounted() {
    await this.getAllSchoolOptions()
  },

  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      if (this.$validate.DataValid(this.id)) {
        // edit
        this.getStudentById()
      } else {
        // new
        const selectedSchool = await this.getUserSelectedSchool()
        if (this.$validate.DataValid(selectedSchool)) {
          this.formData.school_id = selectedSchool
        }
        this.$store.dispatch('toggleLoadingPage', false)
      }

      const userType = this.getUserType()
      if (!userType) {
        this.canViewOrder = false
        this.canViewAttendance = false
        this.canRemission = false
        this.canQuit = false
        this.canEdit = false
      } else {
        if (
          cannotAccessList['OrderList'] &&
          cannotAccessList['OrderList'].length &&
          cannotAccessList['OrderList'].includes(userType)
        ) {
          this.canViewOrder = false
        }
        if (
          cannotAccessList['StudentAttendance'] &&
          cannotAccessList['StudentAttendance'].length &&
          cannotAccessList['StudentAttendance'].includes(userType)
        ) {
          this.canViewAttendance = false
        }
        if (
          cannotAccessList['remissStudent'] &&
          cannotAccessList['remissStudent'].length &&
          cannotAccessList['remissStudent'].includes(userType)
        ) {
          this.canRemission = false
        }
        if (
          cannotAccessList['quitStudent'] &&
          cannotAccessList['quitStudent'].length &&
          cannotAccessList['quitStudent'].includes(userType)
        ) {
          this.canQuit = false
        }
        if (
          cannotAccessList['editStudent'] &&
          cannotAccessList['editStudent'].length &&
          cannotAccessList['editStudent'].includes(userType)
        ) {
          this.canEdit = false
        }
      }
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else if (this.$store.getters.dataIsUpdated) {
      this.setLeaveDialogLink({ name: to.name })
      this.setShowLeaveDialog(true)
      next(false)
    } else {
      next()
    }
  },
}
</script>
